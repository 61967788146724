import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  Button,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { navigate } from 'gatsby';
import PaymentForm from './payment-form';
import PaymentFormByMail from './payment-form-bymail';
import PaymentFormDeposit from './payment-form-deposit';
import { submitPayment } from '../services/payment.service';
import {
  DIRECT_DEPOSIT,
  PAPER_CHECK_BY_MAIL,
  PAYPAL,
  VENMO,
  ZELLE,
  paymentTypeValues,
} from '../constants';
import { usePaymentMethod } from '../store';
import logAnalyticsEvent from '../utils/log-analytics-event';

const Placeholder = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        style={{
          background: '#3F77B8',
          width: 48,
          height: 48,
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        mb={'16px'}
      >
        <TimerOutlinedIcon
          style={{
            borderRadius: '50%',
            color: 'white',
          }}
        />
      </Box>
      <Typography variant="h4" mb={'16px'} textAlign="center">
        Wait for Results
      </Typography>
      <Typography textAlign="center" maxWidth={240}>
        Please stand-by while your claim form is reviewed
      </Typography>
    </Grid>
  );
};

const submitIsDisabled = (formValues) => {
  const { email, phone, accountNumber, routingNumber, paymentType } =
    formValues;
  const isDirectDepositValid =
    paymentType === DIRECT_DEPOSIT && accountNumber && routingNumber;
  const isPaperCheckValid = paymentType === PAPER_CHECK_BY_MAIL;
  const isElectronicValid =
    [PAYPAL, VENMO, ZELLE].includes(paymentType) && (email || phone);
  return !(isDirectDepositValid || isPaperCheckValid || isElectronicValid);
};

const PaymentCard = ({
  caseID,
  validated,
  orderID,
  loadingCallback,
  openSnackbar,
}) => {
  useEffect(() => {
    logAnalyticsEvent('payment_method_form_view', {
      claimId: orderID,
      caseId: caseID
    })
  }, [])
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const { setPaymentMethod } = usePaymentMethod();
  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
      accountNumber: '',
      routingNumber: '',
      accountType: '',
      paymentType: '',
    },
    onSubmit: async (values) => {
      loadingCallback(true);
      setErrorEmail('');
      setErrorPhone('');
      const emailRegex = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'gm');
      const phoneRegex = new RegExp(
        /^(1\s?)?(\d{3}|\(\d{3}\))[\s-]?\d{3}[\s-]?\d{4}$/,
        'gm'
      );
      const isValidEmail = emailRegex.test(values.email);
      const isValidPhone = phoneRegex.test(values.phone);
      const shouldVerifyEmailOrPhone = [PAYPAL, VENMO, ZELLE].includes(
        values.paymentType
      );
      try {
        if (
          !shouldVerifyEmailOrPhone ||
          ((!values.email || isValidEmail) && (!values.phone || isValidPhone))
        ) {
          const bodyPayment = {
            paymentMethod: values.paymentType,
            phone: values.phone,
            email: values.email,
            orderID: orderID,
            caseID: caseID,
            accountNumber: values.accountNumber,
            routingNumber: values.routingNumber,
            accountType: values.accountType,
          };
          await submitPayment(bodyPayment);
          logAnalyticsEvent('payment_method_form_submit_success', {
            paymentMethod: values.paymentType,
            claimId: orderID,
            caseId: caseID
          })
          setPaymentMethod(values.paymentType);
          navigate('/results');
        } else {
          if (!isValidEmail && values.email)
            setErrorEmail(`Invalid email format`);
          if (!isValidPhone && values.phone)
            setErrorPhone(`Invalid phone format`);
        }
      } catch (err) {
        logAnalyticsEvent('payment_method_form_submit_error', {
          caseID: caseID,
          claimID: orderID,
        })
        const errorMessage =
          'Experiencing an unexpected error. Please try reloading the page.';
        openSnackbar(errorMessage, 'error');
      } finally {
        loadingCallback(false);
      }
    },
  });

  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <CardContent>
          <Typography sx={{ fontWeight: 500, fontSize: '24px' }}>
            Payment Options
          </Typography>
        </CardContent>
        <Divider />
        <CardContent>
          {validated ? (
            <Grid container spacing={2} sx={{ mb: 2 }} direction='column'>
              <Grid item>
                <Typography variant="overline" color="primary">
                  STEP 1
                </Typography>
                <Typography
                  sx={{ fontWeight: 700, fontSize: '32px', lineHeight: '32px' }}
                >
                  Choose your payment method
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ fontWeight: 400, fontSize: '16px' }}>
                  Please select a convenient way to receive your payout. We will
                  walk you through a validation process for the method you
                  select.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="overline" color="primary">
                  STEP 2
                </Typography>
                <Typography
                  sx={{ fontWeight: 700, fontSize: '32px', lineHeight: '32px' }}
                >
                  Receive your payout
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ fontWeight: 400, fontSize: '16px' }}>
                  You will receive payment (a) within twenty-one (21) days after the time for appeal or writ of the Final Approval Order and Final Judgment has expired; or (b) if there is an appeal and the Settlement and/or the Final Approval Order and Final Judgment are affirmed, within twenty-one (21) days after the expiration of the last day to the time period during which further petition for hearing, appeal, or writ of certiorari can be taken has expired.
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  fontStyle="italic"
                  mt={-1}
                  sx={{ fontWeight: 400, fontSize: '12px' }}
                >
                  <em>
                    The final amounts paid may be reduced based on total number of claims received.
                  </em>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <InputLabel sx={{ pb: 2 }} id="payment-method">
                  Payment Method
                </InputLabel>
                <TextField
                  fullWidth
                  select
                  labelId="payment-method"
                  name="paymentType"
                  id="payment-method"
                  label={
                    paymentTypeValues.find(
                      (paymentType) =>
                        paymentType.value === formik.values.paymentType
                    )?.label
                      ? 'Payment Method'
                      : 'Select your preferred payment method'
                  }
                  displayEmpty
                  renderValue={(paymentMethod) =>
                    paymentTypeValues.find(
                      (paymentType) => paymentType.value === paymentMethod
                    )?.label || 'Select your preferred payment method'
                  }
                  value={formik.values.paymentType}
                  onChange={formik.handleChange}
                >
                  {paymentTypeValues.map((paymentMethod) => (
                    <MenuItem
                      value={paymentMethod.value}
                      key={paymentMethod.value}
                    >
                      {paymentMethod.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {(() => {
                if (!formik.values.paymentType) {
                  return null;
                }
                return (
                  <>
                    {formik.values.paymentType === PAPER_CHECK_BY_MAIL ? (
                      <PaymentFormByMail />
                    ) : formik.values.paymentType === DIRECT_DEPOSIT ? (
                      <PaymentFormDeposit
                        onChange={formik.handleChange}
                        accountNumber={formik.values.accountNumber}
                        routingNumber={formik.values.routingNumber}
                        accountType={formik.values.accountType}
                      />
                    ) : (
                      <PaymentForm
                        onChange={formik.handleChange}
                        email={formik.values.email}
                        phone={formik.values.phone}
                        errorEmail={errorEmail}
                        errorPhone={errorPhone}
                      />
                    )}
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        flexGrow: '1',
                        maxHeight: '72px',
                      }}
                    >
                      <Button
                        key="paymentButton"
                        color="primary"
                        variant="contained"
                        type="submit"
                        fullWidth
                        disabled={submitIsDisabled(formik.values)}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </>
                );
              })()}
            </Grid>
          ) : (
            <Placeholder />
          )}
        </CardContent>
      </form>
    </Card>
  );
};

export default PaymentCard;
