import { getApp } from 'firebase/app';
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics';
import transformIntoSnakeCase from './transform-object-into-snake-case';

let analytics: ReturnType<typeof getAnalytics> | null = null;

const logAnalyticsEvent = async (
  eventName: string,
  eventParams: object = {}
) => {
  if (!analytics) {
    const supported = await isSupported();
    if (supported) {
      const app = getApp();
      analytics = getAnalytics(app);
    }
  }

  if (analytics) {
    const formattedEventParams = transformIntoSnakeCase(eventParams);
    return logEvent(analytics, eventName, {
      ...formattedEventParams,
    });
  }

  return Promise.resolve();
};

export default logAnalyticsEvent;
