import {
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  Grid,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import { graphql, navigate } from 'gatsby';
import { getUserID } from '../hooks/useFirebase';
import Layout from '../components/layout/layout';
import { theme } from '../theme';
import PaymentCard from '../components/paymentCard';
import { LoadingOverlay } from '../components/loading-overlay/loading-overlay';
import {
  stepsMessage,
  claimTypeValues,
  explanationMessages,
  validationMessages,
  claimResultValues,
} from '../constants';
import { useSnackbar, useClaimStatus } from '../store';
import { SnackBar } from '../components/snackbar';
import logAnalyticsEvent from '../utils/log-analytics-event';

const ClaimCheck = ({ data }) => {
  const userID = getUserID();
  const { directNotice, pop, nonPop } = claimTypeValues;

  const caseID = data.template.caseID;
  const [step, setStep] = useState(0);
  const [claimResult, setClaimResult] = useState();
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();
  const {
    claimID,
    message,
    claimType,
    claimantIdentity,
    subscribeToClaimStatus,
  } = useClaimStatus();

  const stepHandler = (_message, _step) => {
    let newStep = _step;

    switch (_message) {
      case stepsMessage.step1: {
        const stepMessageValue = 1;
        logAnalyticsEvent('claim_check_1_queued', {
          claimID,
          caseID,
        })
        if (_step < stepMessageValue) {
          newStep = stepMessageValue;
        }
        return setStep(newStep);
      }
      case stepsMessage.step2: {
        const stepMessageValue = 2;
        logAnalyticsEvent('claim_check_2_assigned', {
          claimID,
          caseID,
        })
        if (_step < stepMessageValue) {
          newStep = stepMessageValue;
        }
        return setStep(newStep);
      }
      case stepsMessage.step3: {
        const stepMessageValue = 3;
        logAnalyticsEvent('claim_check_3_processing', {
          claimID,
          caseID,
        })
        if (_step < stepMessageValue) {
          newStep = stepMessageValue;
        }
        return setStep(newStep);
      }
      case stepsMessage.step4: {
        const stepMessageValue = 4;
        logAnalyticsEvent('claim_check_4_processed', {
          claimID,
          caseID,
        })

        if (_step < stepMessageValue) {
          newStep = stepMessageValue;
        }
        return setStep(newStep);
      }
      case stepsMessage.step5_a: {
        const stepMessageValue = 5;
        logAnalyticsEvent('claim_check_5_approved', {
          claimID,
          caseID,
        })
        if (_step < stepMessageValue) {
          newStep = stepMessageValue;
        }
        setClaimResult(claimResultValues.confirmed);
        if (claimantIdentity !== 'individual') navigate('/results');
        return setStep(newStep);
      }
      case stepsMessage.step5_b: {
        const stepMessageValue = 5;
        logAnalyticsEvent('claim_check_5_rejected', {
          claimID,
          caseID,
        })

        if (_step < stepMessageValue) {
          newStep = stepMessageValue;
        }
        setClaimResult(claimResultValues.rejected);
        navigate('/results');
        return setStep(newStep);
      }
      default:
    }
  };

  useEffect(() => {
    logAnalyticsEvent('claim_check_page_visit', {
      caseID,
      userID,
    });
    return subscribeToClaimStatus(caseID, userID);
  }, []);

  useEffect(() => {
    if (message) {
      stepHandler(message, step);
    }
  }, [message]);

  const isSM = useMediaQuery(theme.breakpoints.down('sm'));
  const validationMessage = `Validation in Progress: Step ${step}/5`;

  const validationMessageHandler = (_step, _claimType, _validationMessage) => {
    if (_step === 5) {
      switch (_claimType) {
        case directNotice:
          return validationMessages.directNotice;
        case pop:
          return validationMessages.pop;
        case nonPop:
          return validationMessages.nonPop;
      }
    }

    return _validationMessage;
  };

  const explanationMessageHandler = (_step, _claimType) => {
    if (_step === 5) {
      switch (_claimType) {
        case directNotice:
          return explanationMessages.directNotice;
        case pop:
          return explanationMessages.pop;
        case nonPop:
          return explanationMessages.nonPop;
      }
    }

    return explanationMessages.inReview;
  };

  return (
    <>
      <LoadingOverlay open={loading} />
      <Layout>
        <SnackBar>
          <Box sx={{ mb: 8 }}>
            <Typography sx={{ fontWeight: 700, fontSize: '48px' }}>
              Submit a Claim
            </Typography>
            <Typography
              color="text.disabled"
              sx={{ fontWeight: 400, fontSize: { xs: '14px', md: '26px' } }}
            >
              Result & Payment Information
            </Typography>
          </Box>
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <Typography sx={{ fontWeight: 500, fontSize: '24px' }}>
                Claim Submission Results
              </Typography>
            </CardContent>
            <Divider />
            <CardContent>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent={isSM ? 'center' : 'left'}
                paddingTop="4px"
              >
                <Grid style={{ alignItems: 'left', justifyContent: 'left' }}>
                  {step === 5 ? (
                    <CheckIcon
                      style={{
                        background: claimType === pop ? '#F1D64E' : '#3E8682',
                        borderRadius: '100%',
                        width: '48px',
                        height: '48px',
                        padding: '8px',
                        color: 'white',
                      }}
                    />
                  ) : (
                    <CircularProgress color="primary" />
                  )}
                </Grid>
                <Grid style={{ alignItems: 'left', justifyContent: 'left' }}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: '32px',
                      lineHeight: '32px',
                      paddingLeft: '16px',
                      textAlign: 'center',
                      marginTop: isSM ? '24px' : '0px',
                    }}
                  >
                    {validationMessageHandler(
                      step,
                      claimType,
                      validationMessage
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '16px',
                  paddingTop: '28px',
                  textAlign: isSM ? 'center' : 'left',
                }}
              >
                {explanationMessageHandler(step, claimType)}
              </Typography>
            </CardContent>
          </Card>
          {
            claimantIdentity === 'individual' && (
              <Card>
                <PaymentCard
                  caseID={caseID}
                  validated={claimResult === claimResultValues.confirmed}
                  orderID={claimID}
                  loadingCallback={setLoading}
                  openSnackbar={openSnackbar}
                />
              </Card>
            )
          }
        </SnackBar>
      </Layout>
    </>
  );
};

export default ClaimCheck;

export const Head = ({ data }) => (
  <title>ClaimCheck | {data.template.caseName}</title>
);

export const query = graphql`
  query SubmitClaim($id: StringQueryOperatorInput = {}) {
    template(id: $id) {
      caseID
      caseName
    }
  }
`;
